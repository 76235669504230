import { duration } from "moment";

const buttonsContent = {
  submit: "Submit",
  login: "Login",
  cancel: "Cancel",
};
//content for login page
const loginPageContent = {
  login: "Login",
  emailLabel: "Email",
  passwordLabel: "Password",
  buttonValue: "Login",
  forgotPassword: "Forgot Password",
};
const profilePicture = {
  uploadPicture: "Upload Picture",
  removePicture: "Remove Picture",
  takePicture: "Take Picture",
};

const gyminfo = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "Gym Info",
  name: "Gym Name",
  username: "User Name",
  phone: "Phone Number",
  PlanType: "Plan type",
  editinfo: "Edit info",
  trainingTitle: "Live Training",
  location: "Location",
  amenities: "Amenities",
  activities: "Activities",
};
const gyminfoedit = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "Gym Info",
  name: "Gym Name",
  username: "User Name",
  phonenumber: "Phone Number",
  location: "Location",
  amenities: "Amenities",
  activities: "Activities",
};

const trainerInfo = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "Trainer Info",
  name: "Trainer Name",
  username: "User Name",
  phonenumber: "Phone Number",
  livetraining: "Live Training",
  location: "Location",
  trainerstatus: "Trainer status",
  additionalTitle: "Additional Info",
  specialityTitle: "Specialities",
  qualification: "Qualification",
  age: "Age",
};

const trainerEditContent = {
  email: "Email",
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "Instructor Info",
  name: "Instructor Name",
  username: "User Name",
  phonenumber: "Phone Number",
  livetraining: "Live Training",
  location: "Location",
  trainerstatus: "Instructor status",
  additionalTitle: "Additional Info",
  specialityTitle: "Specialities",
  qualification: "Qualification",
  qualificationPlaceholder: "Press Enter after adding each qualification",
  numberNotice: "Max number of qualifications is 8",
  genderTitle: "Gender",
  dob: "Birth Date",
  scheduling: "Scheduling",
};

const settingContent = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
};

const membersInfo = {
  basicProfile: "Basic Profile",
  basicContent: "These info will be shown in your profile page",
  infotitle: "User Info",
  name: "User Name",
  email: "Email",
  phone: "Phone Number",
  date: "Date Of Birth",
  userid: "User Id",
  gender: "Gender",
  subscriptionplan: "Subscription Plan",
  subscriptionstatus: "status",
  olderNotes:'Older Notes',
  note :"Note"

};
const membersInfoEdit = {
  basicProfile: "Basic Profile",
  basicContent: "These info will be shown in your profile page",
  infotitle: "User Info",
  name: "User Name",
  firstname: "First Name",
  lastname: "Last Name",
  email: "Email",
  phone: "Phone Number",
  date: "Date Of Birth",
  userid: "User Id",
  gender: "Gender",
  subscriptionplan: "Subscription Plan",
  subscriptionstatus: "Subscription status",
  paymentstatus: "Payment Status",
  remainingbalance: "Remaining Balance",
  receivedamount: "Received Amount",
  duration: "Duration",
  familymembership: "Family Membership",
  firstname: "First Name",
  lastname: "Last Name",
  returnDescription:" Once confirmed, this member will return to perform any activity on the app or the web on this membership.",
  voidDescription:"     Once confirmed, this member will no longer be able to perform any activity on the app or the web on this membership."

};
const wodInfo = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "Trainer Info",
  name: "Trainer Name",
  username: "User Name",
  phonenumber: "Phone Number",
  livetraining: "Live Training",
  location: "Location",
  trainerstatus: "Trainer status",
  additionalTitle: "Additional Info",
  specialityTitle: "Specialities",
  qualification: "Qualification",
};

const wodEditContent = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "WOD Info",
  name: "Workout Name",
  username: "User Name",
  date: "Select Date",
  instruction: "Start Typing",
};
const packageInfo = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "Trainer Info",
  name: "Trainer Name",
  username: "User Name",
  phonenumber: "Phone Number",
  livetraining: "Live Training",
  location: "Location",
  trainerstatus: "Trainer status",
  additionalTitle: "Additional Info",
  specialityTitle: "Specialities",
  qualification: "Qualification",
};
const packageEditContent = {
  basicProfile: "Basic Profile",
  basiccContent: "These info will be shown in your profile page",
  infotitle: "Workout Info",
  title: "Title",
  numberOfSessions: "Number Of Sessions",
  date: "Select Date",
  instruction: "Start Typing",
};

//content for profile and user profile page inputs
export const Buttons = buttonsContent;
export const LoginPageContent = loginPageContent;
export const ProfilePicture = profilePicture;
export const Gyminfo = gyminfo;
export const GymInfoEditContent = gyminfoedit;
export const TrainerInfoContent = trainerInfo;
export const TrainerEditContent = trainerEditContent;
export const SettingContent = settingContent;
export const MembersInfo = membersInfo;
export const MembersInfoEdit = membersInfoEdit;
export const WodInfoContent = wodInfo;
export const WodEditContent = wodEditContent;
export const PackageInfoContent = packageInfo;
export const PackageEditContent = packageEditContent;
// export const ChangePassword = changePassword;

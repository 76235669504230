import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';

const BranchList = ({ branches,setFieldValue }) => {
    const [selectedBranches, setSelectedBranches] = useState([]);

    useEffect(()=>{

        setFieldValue("branches",selectedBranches)
        
    },[selectedBranches])

    const handleCheckboxChange = (id) => {
        // Check if the branch is already selected
        const isSelected = selectedBranches.includes(id);

        // Update the selectedBranches array based on user's action
        if (isSelected) {
            setSelectedBranches(selectedBranches.filter((branchId) => branchId !== id));
        } else {
            setSelectedBranches([...selectedBranches, id]);
        }

    };

    return (
        <div>
            {console.log({ branches })}
            <FormGroup>
                {branches?.map((branch) => (
                    <Col md={6}>
                        <div className="subscription-plan-list-element">
                            <div className="selction-box mt-0 px-0">
                                <div className="checkboxes-list form-check">
                                    <label
                                        className="container-blk form-check-label"

                                    >
                                        <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="permissions"
                                            onChange={() => handleCheckboxChange(branch.id)}

                                            checked={selectedBranches.includes(branch.id)}

                            
                                        />
                                        <span class="checkmark"></span>
                                        {branch.user.name}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Col>
                    // <Col md={4}>
                    //     <FormGroup check key={branch.id}>
                    //         <Label check className='branches'>
                    //             <Input
                    //                 type="checkbox"
                    //                 checked={selectedBranches.includes(branch.id)}
                    //                 onChange={() => handleCheckboxChange(branch.id)}
                    //             />{' '}
                    //             {branch?.user?.name}
                    //         </Label>
                    //     </FormGroup>
                    //     </Col>

                ))}
            </FormGroup>


        </div>
    );
};

export default BranchList;

import React from "react";
import { ComonIcons } from "./commonIcons";

const navigationConfig = [
  {
    id: "dashboards",
    title: "Dashboard",
    type: "item",
    icon: (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Gym-Admin"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity="0.5"
        >
          <g id="Gym-Info" transform="translate(-32.000000, -515.000000)">
            <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
              <g id="Users" transform="translate(0.000000, 434.000000)">
                <g
                  id="assignment_ind-24px"
                  transform="translate(32.000000, 11.000000)"
                >
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M19,3 L14.82,3 C14.4,1.84 13.3,1 12,1 C10.7,1 9.6,1.84 9.18,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M12,2.75 C12.22,2.75 12.41,2.85 12.55,3 C12.67,3.13 12.75,3.31 12.75,3.5 C12.75,3.91 12.41,4.25 12,4.25 C11.59,4.25 11.25,3.91 11.25,3.5 C11.25,3.31 11.33,3.13 11.45,3 C11.59,2.85 11.78,2.75 12,2.75 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M12,6 C10.35,6 9,7.35 9,9 C9,10.65 10.35,12 12,12 C13.65,12 15,10.65 15,9 C15,7.35 13.65,6 12,6 Z M12,10 C11.45,10 11,9.55 11,9 C11,8.45 11.45,8 12,8 C12.55,8 13,8.45 13,9 C13,9.55 12.55,10 12,10 Z M6,16.47 L6,18 L18,18 L18,16.47 C18,13.97 14.03,12.89 12,12.89 C9.97,12.89 6,13.96 6,16.47 Z M8.31,16 C9,15.44 10.69,14.88 12,14.88 C13.31,14.88 15.01,15.44 15.69,16 L8.31,16 Z"
                    id="Shape"
                    fill="#000000"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
    navLink: "/dashboard"
  },
  {
    id: "branches",
    code: "GYM",
    title: "Branches",
    type: "item",
    navLink: "/branches",
    icon: (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Admin-account"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity="0.5"
        >
          <g
            id="Admin-Dashboard"
            transform="translate(-32.000000, -594.000000)"
          >
            <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
              <g id="Gyms" transform="translate(0.000000, 513.000000)">
                <g
                  id="fitness_center-24px"
                  transform="translate(32.000000, 11.000000)"
                >
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M20.57,14.86 L21.29,14.14 C21.68,13.75 21.68,13.12 21.29,12.73 L21.27,12.71 C20.88,12.32 20.25,12.32 19.86,12.71 L17,15.57 L8.43,7 L11.29,4.14 C11.68,3.75 11.68,3.12 11.29,2.73 L11.27,2.71 C10.88,2.32 10.25,2.32 9.86,2.71 L9.14,3.43 L8.42,2.71 C8.03,2.32 7.39,2.32 7,2.71 L5.57,4.14 L4.85,3.42 C4.46,3.03 3.81,3.03 3.42,3.42 C3.03,3.81 3.03,4.46 3.42,4.85 L4.14,5.57 L2.71,7 C2.32,7.39 2.32,8.02 2.71,8.41 L3.43,9.13 L2.71,9.86 C2.32,10.25 2.32,10.88 2.71,11.27 L2.73,11.29 C3.12,11.68 3.75,11.68 4.14,11.29 L7,8.43 L15.57,17 L12.71,19.86 C12.32,20.25 12.32,20.88 12.71,21.27 L12.73,21.29 C13.12,21.68 13.75,21.68 14.14,21.29 L14.86,20.57 L15.58,21.29 C15.97,21.68 16.6,21.68 16.99,21.29 L18.42,19.86 L19.14,20.58 C19.53,20.97 20.18,20.97 20.57,20.58 C20.96,20.19 20.96,19.54 20.57,19.15 L19.85,18.43 L21.29,17 C21.68,16.61 21.68,15.98 21.29,15.59 L20.57,14.86 Z"
                    id="Path"
                    fill="#000000"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  },
  {
    id: "sub_admin",
    title: "Admins",
    code: "SUB_ADMIN",
    type: "item",
    icon: (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Admin-account"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity="0.5"
        >
          <g
            id="Admin-Dashboard"
            transform="translate(-32.000000, -648.000000)"
          >
            <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
              <g id="Trainers" transform="translate(0.000000, 567.000000)">
                <g
                  id="self_improvement-24px"
                  transform="translate(32.000000, 11.000000)"
                >
                  <g id="Group">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                  </g>
                  <g
                    id="Group"
                    transform="translate(3.000000, 4.000000)"
                    fill="#000000"
                    fill-rule="nonzero"
                  >
                    <circle id="Oval" cx="9" cy="2" r="2"></circle>
                    <path
                      d="M18,10.94 L18,10.94 C18,10.44 17.64,10.01 17.15,9.96 C15.27,9.75 13.66,8.83 12.4,7.33 L11.06,5.73 C10.68,5.26 10.12,5 9.53,5 L8.48,5 C7.89,5 7.33,5.26 6.95,5.72 L5.61,7.32 C4.36,8.82 2.74,9.74 0.86,9.95 C0.36,10.01 0,10.44 0,10.94 L0,10.94 C0,11.54 0.53,12.01 1.13,11.94 C3.43,11.67 5.45,10.55 7,8.75 L7,11 L3.24,12.5 C2.59,12.76 2.08,13.33 2.01,14.03 C1.91,15.1 2.74,16 3.79,16 L6,16 L6,15.5 C6,14.12 7.12,13 8.5,13 L11.5,13 C11.78,13 12,13.22 12,13.5 C12,13.78 11.78,14 11.5,14 L8.5,14 C7.67,14 7,14.67 7,15.5 L7,16 L14.1,16 C14.95,16 15.75,15.46 15.95,14.63 C16.16,13.74 15.68,12.87 14.87,12.55 L11,11 L11,8.75 C12.56,10.55 14.57,11.66 16.87,11.94 C17.47,12 18,11.54 18,10.94 Z"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/adminInfolist"
  },
  {
    id: "trainers",
    title: "Trainers",
    code: "TRN",
    type: "item",
    icon: (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Admin-account"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity="0.5"
        >
          <g
            id="Admin-Dashboard"
            transform="translate(-32.000000, -648.000000)"
          >
            <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
              <g id="Trainers" transform="translate(0.000000, 567.000000)">
                <g
                  id="self_improvement-24px"
                  transform="translate(32.000000, 11.000000)"
                >
                  <g id="Group">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                  </g>
                  <g
                    id="Group"
                    transform="translate(3.000000, 4.000000)"
                    fill="#000000"
                    fill-rule="nonzero"
                  >
                    <circle id="Oval" cx="9" cy="2" r="2"></circle>
                    <path
                      d="M18,10.94 L18,10.94 C18,10.44 17.64,10.01 17.15,9.96 C15.27,9.75 13.66,8.83 12.4,7.33 L11.06,5.73 C10.68,5.26 10.12,5 9.53,5 L8.48,5 C7.89,5 7.33,5.26 6.95,5.72 L5.61,7.32 C4.36,8.82 2.74,9.74 0.86,9.95 C0.36,10.01 0,10.44 0,10.94 L0,10.94 C0,11.54 0.53,12.01 1.13,11.94 C3.43,11.67 5.45,10.55 7,8.75 L7,11 L3.24,12.5 C2.59,12.76 2.08,13.33 2.01,14.03 C1.91,15.1 2.74,16 3.79,16 L6,16 L6,15.5 C6,14.12 7.12,13 8.5,13 L11.5,13 C11.78,13 12,13.22 12,13.5 C12,13.78 11.78,14 11.5,14 L8.5,14 C7.67,14 7,14.67 7,15.5 L7,16 L14.1,16 C14.95,16 15.75,15.46 15.95,14.63 C16.16,13.74 15.68,12.87 14.87,12.55 L11,11 L11,8.75 C12.56,10.55 14.57,11.66 16.87,11.94 C17.47,12 18,11.54 18,10.94 Z"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/trainerinfolist"
  },
  {
    id: "staff",
    title: "Staff",
    code: "st",
    type: "item",
    icon: (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><title>796B5807-4CEB-49EC-B356-C9F0BFAC9012@1.5x</title><g id="Gym-Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Staff-Page" transform="translate(-32.000000, -569.000000)"><g id="Group-15" transform="translate(0.000000, 536.000000)"><g id="Gyms" transform="translate(0.000000, 22.000000)"><g id="people_outline_black_24dp" transform="translate(32.000000, 11.000000)"><polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon><path d="M9,12 C10.93,12 12.5,10.43 12.5,8.5 C12.5,6.57 10.93,5 9,5 C7.07,5 5.5,6.57 5.5,8.5 C5.5,10.43 7.07,12 9,12 Z M9,7 C9.83,7 10.5,7.67 10.5,8.5 C10.5,9.33 9.83,10 9,10 C8.17,10 7.5,9.33 7.5,8.5 C7.5,7.67 8.17,7 9,7 Z M9,13.75 C6.66,13.75 2,14.92 2,17.25 L2,18 C2,18.55 2.45,19 3,19 L15,19 C15.55,19 16,18.55 16,18 L16,17.25 C16,14.92 11.34,13.75 9,13.75 Z M4.34,17 C5.18,16.42 7.21,15.75 9,15.75 C10.79,15.75 12.82,16.42 13.66,17 L4.34,17 Z M16.04,13.81 C17.2,14.65 18,15.77 18,17.25 L18,19 L21,19 C21.55,19 22,18.55 22,18 L22,17.25 C22,15.23 18.5,14.08 16.04,13.81 Z M15,12 C16.93,12 18.5,10.43 18.5,8.5 C18.5,6.57 16.93,5 15,5 C14.46,5 13.96,5.13 13.5,5.35 C14.13,6.24 14.5,7.33 14.5,8.5 C14.5,9.67 14.13,10.76 13.5,11.65 C13.96,11.87 14.46,12 15,12 Z" id="Shape" fill="#8700A9" fill-rule="nonzero"></path></g></g></g></g></g></svg>
    ),
    navLink: "/staff"
  },
  {
    id: "members",
    title: "Members",
    type: "item",
    code: "MEM",
    icon: (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Gym-Admin"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity="0.5"
        >
          <g id="Gym-Info" transform="translate(-32.000000, -515.000000)">
            <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
              <g id="Users" transform="translate(0.000000, 434.000000)">
                <g
                  id="assignment_ind-24px"
                  transform="translate(32.000000, 11.000000)"
                >
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M19,3 L14.82,3 C14.4,1.84 13.3,1 12,1 C10.7,1 9.6,1.84 9.18,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M12,2.75 C12.22,2.75 12.41,2.85 12.55,3 C12.67,3.13 12.75,3.31 12.75,3.5 C12.75,3.91 12.41,4.25 12,4.25 C11.59,4.25 11.25,3.91 11.25,3.5 C11.25,3.31 11.33,3.13 11.45,3 C11.59,2.85 11.78,2.75 12,2.75 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M12,6 C10.35,6 9,7.35 9,9 C9,10.65 10.35,12 12,12 C13.65,12 15,10.65 15,9 C15,7.35 13.65,6 12,6 Z M12,10 C11.45,10 11,9.55 11,9 C11,8.45 11.45,8 12,8 C12.55,8 13,8.45 13,9 C13,9.55 12.55,10 12,10 Z M6,16.47 L6,18 L18,18 L18,16.47 C18,13.97 14.03,12.89 12,12.89 C9.97,12.89 6,13.96 6,16.47 Z M8.31,16 C9,15.44 10.69,14.88 12,14.88 C13.31,14.88 15.01,15.44 15.69,16 L8.31,16 Z"
                    id="Shape"
                    fill="#000000"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
    navLink: "/memberslist"
  },
  // {
  //   id: "managetemplate",
  //   title: "Manage Email Templates",
  //   code: "ET",
  //   type: "item",
  //   icon: (
  //     <svg
  //       width="24px"
  //       height="24px"
  //       viewBox="0 0 24 24"
  //       version="1.1"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <g
  //         id="Admin-account"
  //         stroke="none"
  //         stroke-width="1"
  //         fill="none"
  //         fill-rule="evenodd"
  //         opacity="0.5"
  //       >
  //         <g
  //           id="Admin-Dashboard"
  //           transform="translate(-32.000000, -810.000000)"
  //         >
  //           <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
  //             <g
  //               id="Manage-EMail-Templates"
  //               transform="translate(0.000000, 729.000000)"
  //             >
  //               <g
  //                 id="all_inbox-24px"
  //                 transform="translate(32.000000, 11.000000)"
  //               >
  //                 <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
  //                 <path
  //                   d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,12 C3,13.1 3.9,14 5,14 L19,14 C20.1,14 21,13.1 21,12 L21,5 C21,3.9 20.1,3 19,3 Z M19,9 L15.86,9 C15.39,9 15.02,9.33 14.89,9.78 C14.53,11.04 13.35,12 12,12 C10.65,12 9.47,11.04 9.11,9.78 C8.98,9.33 8.61,9 8.14,9 L5,9 L5,6 C5,5.45 5.45,5 6,5 L18,5 C18.55,5 19,5.45 19,6 L19,9 Z M15.87,16 L20,16 C20.55,16 21,16.45 21,17 L21,19 C21,20.1 20.1,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,17 C3,16.45 3.45,16 4,16 L8.13,16 C8.6,16 8.98,16.34 9.11,16.8 C9.46,18.07 10.62,19 12,19 C13.38,19 14.54,18.07 14.89,16.8 C15.02,16.34 15.4,16 15.87,16 Z"
  //                   id="Shape"
  //                   fill="#000000"
  //                   fill-rule="nonzero"
  //                 ></path>
  //               </g>
  //             </g>
  //           </g>
  //         </g>
  //       </g>
  //     </svg>
  //   ),
  //   navLink: "/manageemail"
  // },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    code: "settings",
    icon: (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Admin-account"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity="0.5"
        >
          <g id="Gym-Info" transform="translate(-32.000000, -836.000000)">
            <g id="Social-media" transform="translate(0.000000, 825.000000)">
              <g
                id="admin_panel_settings-24px"
                transform="translate(32.000000, 11.000000)"
              >
                <g id="Group">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                </g>
                <g
                  id="Group"
                  transform="translate(3.000000, 3.000000)"
                  fill="#000000"
                  fill-rule="nonzero"
                >
                  <path
                    d="M14,8 C14.34,8 14.67,8.04 15,8.09 L15,4.58 C15,3.78 14.53,3.06 13.8,2.75 L8.3,0.35 C7.79,0.13 7.21,0.13 6.7,0.35 L1.2,2.75 C0.47,3.07 0,3.79 0,4.58 L0,8.18 C0,12.72 3.2,16.97 7.5,18 C8.05,17.87 8.58,17.68 9.1,17.45 C8.41,16.47 8,15.28 8,14 C8,10.69 10.69,8 14,8 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M14,10 C11.79,10 10,11.79 10,14 C10,16.21 11.79,18 14,18 C16.21,18 18,16.21 18,14 C18,11.79 16.21,10 14,10 Z M14,11.38 C14.62,11.38 15.12,11.89 15.12,12.5 C15.12,13.11 14.61,13.62 14,13.62 C13.39,13.62 12.88,13.11 12.88,12.5 C12.88,11.89 13.38,11.38 14,11.38 Z M14,16.75 C13.07,16.75 12.26,16.29 11.76,15.58 C11.81,14.86 13.27,14.5 14,14.5 C14.73,14.5 16.19,14.86 16.24,15.58 C15.74,16.29 14.93,16.75 14,16.75 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
    navLink: "/settings"
  },
];

export default navigationConfig;

import React, { useState, useSelector, useEffect } from "react";
import { Formik, Form } from "formik";
import { FormContent } from "./formContent";
import * as Yup from "yup";
// import { addStaffModal } from "../../../redux/actions/sidebar/sidebarAction";
// import { store } from "../../redux/storeConfig/store";
// import { createStaff, udateStaff } from "../../redux/actions/api/apiAction";
import { api } from "../../../api/api";
import { addSubAdminModal } from "../../../redux/actions/sidebar/sidebarAction";
import { store } from "../../../redux/storeConfig/store";
import { createSubAdmin } from "../../../redux/actions/api/apiAction";

const SubAdminForm = ({ name, email, phone, permissions }) => {
    const [permissionsList, setPermissionList] = useState([]);
    useEffect(() => {
        const getPermissions = async () => {
            let url = "permission?type=SUB_ADMIN";
            let res = await api(url, {}, "get");
            if (res.status === 400) {
                setPermissionList([]);
            } else {
                setPermissionList(res.data.data);
            }
        };
        getPermissions();
    }, []);
    const handleSubmit = async values => {
        // if (store.getState().sidebar.staffEdit) {
        //     let id = store.getState().sidebar.staffFields.id;
        //     // await store.dispatch(udateStaff(id, values));
        // } else {
            await store.dispatch(createSubAdmin(values));
        // }
    };

    const submit = values => {
        handleSubmit(values);
    };

    const formSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone: Yup.string()
            .max(25, "cannot be > 25 digits")
            //   .test("already-exist", "Phone already exist", async value => {
            //     console.log("running async validations");
            //     if (value !== undefined) {
            //       let res = await checkValidation({ key: "phone", value: value });
            //       console.log(res);
            //       return !res;
            //     }
            //   })
            .required("Please enter valid phone number"),
        email: Yup.string()
            .email("Please enter valid Username i.e email")
            //   .test("already-exist", "Email already exist", async value => {
            //     console.log("running async validations");
            //     if (value !== undefined && regEmail.test(value)) {
            //       let res = await checkValidation({ key: "email", value: value });
            //       console.log(res);
            //       return !res;
            //     }
            //   })
            .required("Username is required"),
        permissions: Yup.array().required("Select any admin privileges"),
        branches:Yup.array()
    });

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    name,
                    email,
                    phone,
                    permissions

                    // visitCount
                }}
                onSubmit={submit}
                validationSchema={formSchema}
            >
                {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    values,
                    setFieldValue
                }) => (
                    <Form>
                        <FormContent
                            errors={errors}
                            touched={touched}
                            values={values}
                            setFieldValue={setFieldValue}
                            permissionsList={permissionsList}
                        />
                        <div className="bg-white card-panel ex-space-y pt-0">
                            <div className="action-wrapper blk-padding-y">
                                <div className="action-inner-block">
                                    <div className="action-btn-block">
                                        <React.Fragment>
                                            <button
                                                type="button"
                                                className="btn btn-medium btn-transparent"
                                                onClick={() => {
                                                    store.dispatch(addSubAdminModal(false));
                                                    // history.push("/gym-panel/memberslist");
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </React.Fragment>

                                        <button className="btn btn-medium" type="submit">
                                            Save Admin
                                            {/* {store.getState().sidebar.staffEdit
                                                ? "Save Staff"
                                                : "Add Staff"} */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

SubAdminForm.defaultProps = {
    name: "",
    email: "",
    phone: "",
    permissions: [],
    handleSubmit: values => {
        //console.log(values, "values here");
    }
};

export default SubAdminForm;
